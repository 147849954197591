@mixin button($color)
{
	@if $color == white
	{
		$color: $button-color-white;
	}

	color: $color;
	border: 1px solid $color;
	box-shadow: 2px 2px $color;
	background-color:transparent;

	&:not(:disabled)
	{
		@include onAccessible(true)
		{
			background-color:$color;
			box-shadow:none;
			border: 1px solid $color;
			color:$white;
		}
	}
}

@mixin invertedButton($color)
{
	@if $color == white
	{
		color: $button-color-white;
		border: 1px solid $button-color-white;
	}
	@else
	{
		border: 1px solid $color;
		color:$white;
	}
	background-color:$color;
	box-shadow:none;
	&:not(:disabled)
	{
		@include onAccessible(true)
		{
			@if $color == white
			{
				color: $button-color-white;
				box-shadow: 2px 2px $button-color-white;
			}
			@else
			{
				color: $color;
				box-shadow: 2px 2px $color;
				border: 1px solid $color;
			}
			background-color: transparent;
		}
	}
}

button, .rwx-button
{
	cursor:pointer;
}

.rwx-button:not(.no-decoration):not(.rwx-link), button:not(.no-decoration):not(.rwx-link)
{
	@include flex-center;
	display:inline-flex;
	min-height:$button-size;
	padding-left: $button-padding;
	padding-right: $button-padding;
	transition: all 0.7s $transition-base;
	border-radius:4px;
	@include h6;
	@include onAccessible(true)
	{
		outline:none;
		transition: all 1s $transition-base;
	}

	&.\--bullet
	{
		border-radius:$button-padding*2;
	}
	
	@include button($button-color-base);

	&.\--inverted
	{
		@include invertedButton($button-color-base);	
	}

	@each $modifier, $color in $colors
	{
		&.\--#{$modifier}
		{
			@include button($color);
			&.\--inverted
			{
				@include invertedButton($color);
			}
		}
	}

	&:disabled
	{
		opacity:0.5;
	}
}

a.rwx-button
{
	text-decoration: none;
}

button.no-decoration, button.rwx-link
{
	appearance:none;
	background:none;
	outline:none;
	border:none;
	font-size:inherit;
	font:inherit;
	padding:0;
	color:inherit;
}