$border-directions: (
	"r": "right",
	"l": "left",
	"t": "top",
	"b": "bottom"
);

$sizes: (
	"md": "2px",
	"lg": "3px"
);

@each $selector, $color in $colors
{
	.rwxc-background-#{$selector}
	{
		background-color:$color !important;
	}
	.rwxc-color-#{$selector}
	{
		color:$color !important;
	}
	.rwxc-border
	{
		&-#{$selector}
		{
			border: 1px solid $color !important;
			@each $size, $px in $sizes
			{
				&-#{$size}
				{
					border: #{$px} solid $color !important;
				}
			}
		}
		@each $s, $c in $border-directions
		{
			&-#{$s}-#{$selector}
			{
				border-#{$c}: 1px solid $color !important;
				@each $size, $px in $sizes
				{
					&-#{$size}
					{
						border-#{$c}: #{$px} solid $color !important;
					}
				}
			}
		}
	}
}