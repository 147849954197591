$rwx-mist-size: 56px !default;
$rwx-mist-color: $grey !default;

.rwx-mist
{
	&-right, &-bottom
	{
		position:absolute;
		opacity: 1;
		transition: opacity 0.5s linear;
		&.hide
		{
			opacity: 0;
			transition: opacity 0.5s linear;
			z-index:-1;
		}
	}

	&-right
	{
		right:0px;
		top:0px;
		height:100%;
		width: $rwx-mist-size;
		background: linear-gradient(to right, rgba($rwx-mist-color,0), rgba($rwx-mist-color,0.7));
	}

	&-bottom
	{
		left:0px;
		bottom:0px;
		width:100%;
		height:$rwx-mist-size;
		background: linear-gradient(to bottom, rgba($rwx-mist-color,0), rgba($rwx-mist-color,0.7));
	}	
}
