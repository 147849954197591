@mixin change-mist($color, $size:false)
{
	.rwx-mist
	{
		&-right
		{
			@if $size
			{
				width: $size;
			}
			background: linear-gradient(to right, rgba($color, 0), rgba($color, 0.7));
		}

		&-bottom
		{
			@if $size
			{
				height: $size;
			}
			background: linear-gradient(to bottom, rgba($color, 0), rgba($color, 0.7));
		}	
	}
}

@mixin change-loading-color($bg, $color)
{
	&:before
	{
		background: rgba($bg, 0.5);
	}
	&:after
	{
		background:linear-gradient(to bottom, rgba($bg,0), rgba($color,0) 40%, rgba($color,1) 50%, rgba($color,0) 60%, rgba($bg,0));
	}	
}

@mixin change-animated-border($from, $to, $size:false)
{
	svg
	{
		rect
		{
			@if $size
			{
				stroke-width: $size;
			}
			stroke: $from !important;
		}
		@if size
		{
			@include onAccessible(true, 'active')
			{
				rect
				{
					stroke-width: $size;
				}	
			}
		}
	}
	@include onAccessible(true, 'active')
	{
		svg rect {
			stroke: $to !important;
			@if $size
			{
				stroke-width: $size;
			}
		}
	}
}

@mixin change-list-color($color) {
  &:not(.no-decoration)
  {
    &:not(.\--numbered)
    {
      li:before {
        background: $color;
      }
    }
  }
  &.\--numbered
  {
    li:before {
      color: $color;
    }
  }
  &.\--inline
  {
    li
    {
      &:before
      {
        background: $color;
      }
    }
  }
}

@mixin change-input-color($color, $color2) {
	input,select
	{
		border:1px solid $color;
		color:$color;
	}
	label
	{
		color:$color;
	}
	&.rwx-select
	{
		select
		{
			background-image: icon('select-list', $color, '10px', true);
			option
			{
				color: $dark-grey;
			}
		}
	}
	&.rwx-checkbox
	{
		input[type="checkbox"]
		{
			border: 1px solid $color;
			&:checked
			{
				background-image: icon('tick', $color, '30px');
			}
		}
		label:after
		{
			border-color:$color;
		}
	}
	&.rwx-toggle
	{
		input[type="checkbox"]
		{
			background-image: icon('circle', $color);
			&:checked
			{
				background-position: right;
				background-image: icon('circle-fill', $color2);
			}
		}
	}
	&.rwx-radio
	{
		input[type="radio"]
		{
			background-image: icon('circle-fill', $color2);	
		}
	}
}

@mixin focus-ring
{
	outline:none;
	box-shadow: $focus-ring-shadow;
}

@mixin change-link-color($color) {
	color:$color !important;
	&:after
	{
		background-color:$color !important;
	}
}

@mixin flex-center
{
	display:flex;
	justify-content:center;
	align-items:center;
}

@mixin absolute-center
{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}

@mixin full-size
{
	position:absolute;
	top:0px;
	right:0px;
	left:0px;
	bottom:0px;
	height:100%;
	width:100%;
}

@mixin snap-down-from($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	}
	@else {
		@warn "`#{$breakpoint} is not a valid breakpoint. Available breakpoints are: #{map-keys($breakpoints)}`"
	}
}

@mixin snap-up-from($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	}
	@else {
		@warn "`#{$breakpoint} is not a valid breakpoint. Available breakpoints are: #{map-keys($breakpoints)}`"
	}
}

// NOT DOCUMENTED

@mixin impactful
{
  color:$silver;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  display: block;
  margin-bottom: 0.3rem;
}

@mixin hideScrollbar()
{
	&::-webkit-scrollbar {
	  display: none;
	}
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin onAccessible($hover, $extraClass:false)
{
	&:focus, &:active, &:focus-visible
	{
		@content;
	}
	@if $hover
	{
		&:hover
		{
			@content;
		}
	}
	@if $extraClass
	{
		&.#{$extraClass}
		{
			@content;
		}
	}
}