.project
{
	width:0px;
	height:0px;
	position: fixed;
	top:-100px;
	left:-100px;
	z-index:5;
	transition:background 2s $transition-base, border-radius 2s $transition-base;
	border-radius:48px;
	overflow:hidden;
	&.open
	{
		transition:background 2s $transition-base, border-radius 2s $transition-base;
		border-radius:20px;
		background:$white !important;
	}
	box-shadow:0px -3px 22px 6px $dark-grey;
	&-content
	{
		margin-top: 20px;
		margin-bottom: 20px;
		overflow:auto;
		height:100%;
		video
		{
			width:100%;
		}
	}
	.close
	{
		position: absolute;
		top:20px;
		right:20px;
		width:20px;
		height:20px;
		cursor:pointer;
		background: icon('cross', $silver, 50px) no-repeat;
	}
}

.rwx-grid-gallery-item
{
	&.hidden
	{
		visibility: hidden;
	}
}

.rwx-grid-gallery-heading.\--white
{
	color:$black;
}

@each $modifier, $color in $colors
{
	.rwx-grid-gallery-heading.\--#{$modifier}
	{
		background: rgba($color,0.8);
	}
}