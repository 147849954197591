$grid-classes:
  "full" 100%,
  "half" 50%,
  "third" 33.33%,
  "third-x2" 66.66%,
  "quarter" 25%,
  "quarter-x3" 75%,
  "fifth" 20%,
  "fifth-x2" 40%,
  "fifth-x3" 60%,
  "fifth-x4" 80%;

%grid-padding {
	padding-right:$grid-item-gutter*0.5;
	padding-left:$grid-item-gutter*0.5;
	@include snap-down-from('md')
	{
		padding-top:$grid-item-gutter-vertical*0.5;
		padding-bottom:$grid-item-gutter-vertical*0.5;
		padding-left:0px;
		padding-right:0px;
		width:100% !important;
	} 	
}

.rwx-split
{
	display:flex;
	flex-wrap:wrap;

	&.explicit.no-wrap
	{
		padding-left: $grid-item-gutter*0.5;
		padding-right: $grid-item-gutter*0.5;	
		@each $selector, $value in $grid-classes
		{
			> .rwx-split-#{$selector}
			{
				@include snap-down-from('md')
				{
					padding-right:$grid-item-gutter*0.5;
					padding-left:$grid-item-gutter*0.5;
				}
			}
		}
	}

	&.no-wrap
	{
		flex-wrap:nowrap;
		@each $selector, $value in $grid-classes
		{
			> .rwx-split-#{$selector}
			{
				width: #{$value} !important;
			}
		}
	}

	&.explicit
	{
		@extend %grid-padding;
		@include snap-down-from('md')
		{
			padding-left: $grid-item-gutter;
			padding-right: $grid-item-gutter;
		}
		&.align
		{
				padding-left:0px;
				padding-right:0px;
			@include snap-up-from('md')
			{
				margin-left: -$grid-item-gutter*0.5;
				margin-right: -$grid-item-gutter*0.5;
			}
		}
	}

	&.vertical
	{
		flex-direction:column;
		&.center
		{
			align-items:center;
			justify-content:flex-start;
		}
		&.center-vertically
		{
			justify-content:center;
			align-items:stretch;
		}
		&.end-vertically
		{
			justify-content:flex-end;
			align-items:stretch;
		}
		&.end
		{
			justify-content:flex-start;
			align-items:flex-end;
		}
	}

	&.end-vertically
	{
		align-items: flex-end;
	}
	&.center-vertically
	{
		align-items: center;
	}
	&.end
	{
		justify-content:flex-end;
	}
	&.center
	{
		justify-content:center;
	}
	&.space-between
	{
		justify-content: space-between;
	}
	&.space-around
	{
		justify-content: space-around;
	}
	&.space-even
	{
		justify-content: space-around;
		&:before
		{
			content:"";
		}
		&:after
		{
			content: "";
		}
	}

	&.non-fluid
	{
		@include snap-up-from('md')
		{
			max-width: map-get($breakpoints, 'md');
			margin:auto;
		}
		@include snap-up-from('lg')
		{
			max-width: map-get($breakpoints, 'lg');
		}
		@include snap-up-from('xl')
		{
			max-width: map-get($breakpoints, 'xl');
		}
	}

	@each $selector, $value in $grid-classes
	{
		&-#{$selector}
		{
			word-break:break-word;
			width: #{$value};
			position:relative;
			@extend %grid-padding;
		}
	}
}