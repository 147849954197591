.rwx-tabs
{
	position:relative;
	.initial-hide
	{
		position:absolute;
		z-index:-1;
		transform:translateY(-100%);
		visibility:hidden;
	}
	&.\--center
	{
		.rwx-tabs-button-container
		{
			margin:auto;
		}
	}
	&.\--right
	{
		.rwx-tabs-button-container
		{
			margin-left:auto;
		}
	}
	& > &-container
	{
		padding: 10px 20px;
		margin-bottom: 40px;
		display:flex;
		align-items:flex-end;
		border-bottom: 2px solid $tabs-seperator-color;
		position:relative;
		overflow:auto;
		white-space: nowrap;
		button
		{
			margin-bottom:0;
			border-top: 2px solid $tabs-seperator-color;
			border-bottom: 2px solid $tabs-seperator-color;
			border-radius: 10px;
			padding: 10px;
			cursor:pointer;
			color: $tabs-title-color;
			font-size: $tabs-title-size;
			font-family: $font-heading;
			transition: all 0.7s $transition-base;
			@include onAccessible(true, 'active')
			{
				color: $tabs-active-color;
				border-color: $tabs-active-color;
				transition: all 0.7s $transition-base;			
			}
			&.active
			{
				border-bottom:none;
				font-weight: bold;
			}
			&:not(:last-child)
			{
				margin-right: 40px;
			}
		}
		.bullet
		{
			position:absolute;
			bottom:0px;
			height:3px;
			border-radius:10px;
			background-color: $tabs-active-color;
			transition: all 0.5s $transition-base;
		}
	}
	.rwx-tabs
	{
		.rwx-tabs-container
		{
			padding-left:30px;
			padding-right:30px;
			border-bottom: 1px solid $tabs-seperator-color;
			button
			{
				font-size: $tabs-title-size * 0.8;
				border-top: 1px solid $tabs-seperator-color;
				border-bottom: 1px solid $tabs-seperator-color;
				&.active, &:hover
				{
					border-color: $tabs-active-color;
				}
				&.active
				{
					border-bottom:none;
				}
			}
		}
		.bullet
		{
			height:2px;
		}
	}
}