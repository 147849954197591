@import '../core/rwx-variables';
@import '../core/rwx-mixins';
$size: 16px;
.skroll-highjack-navigation
{
	position: fixed;
	top:50%;
	right:0px;
	transform:translateY(-50%);
	background: $white;
	z-index: $top-layer-z;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	box-shadow: 0px 0px 3px 0px $silver;
	transition: width 0.5s $transition-base;
	width:$size + 16;
	&-counter
	{
		width: $size;
		height: $size;
		background: $blue;
		margin-right:16px;
		flex-shrink:0;
		&.active
		{
			background: $orange;
		}
		@include onAccessible(true)
		{
			@include focus-ring;
		}
		&-container
		{
			cursor:pointer;
			display:flex;
			flex-wrap:nowrap;
			align-items:center;
			margin-top:16px;
			margin-left:8px;
			margin-right:8px;
			&:last-child
			{
				margin-bottom:16px;
			}
			span
			{
				@include impactful;
				margin-bottom:0px;
				height: 0;
				opacity: 0;
			}
		}
	}
	&:focus, &:focus-within
	{
		outline:none;
	}
	&:hover, &.active
	{
		width:300px;
		transition: width 0.5s $transition-base;
		.skroll-highjack-navigation-counter-container span
		{
			height: 100%;
			opacity: 1;
			transition: opacity 0.5s $transition-base;
		}
	}
}