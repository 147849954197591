@mixin required
{
	&:after
	{
		content:"*";
		padding-left:8px;
	}
}

.rwx-form
{
	position:relative;
	&-item
	{
		display:flex;
		flex-direction:column;
		margin-bottom:$large-spacing;
		textarea, select[multiple]
		{
			height:$form-item-size*2;
		}
		&.\--inline
		{
			@include snap-up-from('sm')
			{
				flex-direction:row;
				flex-wrap:wrap;
				align-items:center;
				input,select,textarea
				{
					flex-basis:$form-inline-input-size;
				}
				label{
					margin-bottom:0px;
					flex-basis:100 - $form-inline-input-size;
					word-break:break-word;
					padding-right: 24px;
				}
				&.invalid
				{
					.invalid-message
					{
						width:$form-inline-input-size;
						margin-left:100 - $form-inline-input-size;
					}
				}
			}
		}
		label
		{
			color:$form-color-base;
			display:block;
			margin-bottom:10px;
			order:1;
		}
		select
		{
			-webkit-appearance: none;
		}
		input,select
		{
			min-height:$form-item-size;
			height:$form-item-size;
		}
		input, select, textarea
		{
			order:2;
			color: $form-color-base;
			padding-left:16px;
			padding-right:16px;
			width:100%;
			border:1px solid $form-color-secondary;
			transition: 0.5s border $transition-base;
			font: inherit;
			background-color: transparent;
			@include onAccessible(false)
			{
				@include focus-ring;
			}
			&[readonly], &[disabled]
			{
				background-color: $form-color-secondary;
			}
		}

		&.valid, &.invalid
		{
			input, select, textarea
			{
				transition: 0.5s border $transition-base;
			}
		}

		&.valid
		{
			input, select, textarea
			{
				border: 1px solid $form-color-valid;
			}
		}
		&.invalid
		{
			input, select, textarea
			{
				border: 1px solid $form-color-invalid;
			}
			.invalid-message
			{
				display:block;
				width:100%;
				margin-bottom:0px !important;
			}
		}
		.invalid-message
		{
			color: $form-color-invalid;
			order: 3;
			margin-top:5px;
			@extend .small;
			display:none;
		}

		&.required
		{
			label
			{
				@include required;
			}	
		}

		&.rwx-checkbox, &.rwx-toggle, &.rwx-radio
		{
			input[type="checkbox"], input[type="radio"]
			{
				flex-shrink:0;
				appearance:none;
				cursor:pointer;
				margin:0px;
				background-repeat: no-repeat;
				transition: all 0.5s $transition-base;
				&:checked
				{
					transition: all 0.5s $transition-base;
				}
			}
			label
			{
				cursor:pointer;
				position:relative;
			}
		}

		&.rwx-checkbox
		{
			&.\--inline
			{
				@include snap-up-from('sm')
				{
					input[type="checkbox"]
					{
						flex-basis:$form-item-size;
					}
				}
			}
			input[type="checkbox"]
			{
				width:$form-item-size;
				background-image: icon('tick', 'transparent');
				background-size: $form-item-size*0.5;
  			background-position: center;
				&:checked
				{
					background-image: icon('tick', $form-color-base, '30px');
				}
			}
		}

		&.rwx-toggle
		{
			&.\--inline
			{
				@include snap-up-from('sm')
				{
					input[type="checkbox"]
					{
						flex-basis:$form-item-size*2;
					}
				}
			}
			input[type="checkbox"]
			{
				width:$form-item-size*2;
				border-radius: $form-item-size*0.5;
  			background-position: left;
  			background-image: icon('circle', $form-color-secondary);
				&:checked
				{
					background-position: right;
					background-image: icon('circle-fill', $form-color-base);
				}
			}
		}

		&.rwx-radio
		{
			&.\--inline
			{
				@include snap-up-from('sm')
				{
					input[type="radio"]
					{
						flex-basis:$form-item-size;
					}
				}
			}
			input[type="radio"]
			{
				width: $form-item-size;
				background-image: icon('circle-fill', $form-color-base);
				background-size: 0;
  			background-position: center;
  			border-radius:$form-item-size*0.5;
				&:checked
				{
					background-size: $form-item-size;
				}				
			}
		}	

		&.rwx-select
		{
			select
			{
				padding-right:$form-item-size;
				cursor:pointer;
				background-image: icon('select-list', $form-color-base, '10px', true);
				background-size: $form-item-size*0.5;
				background-repeat: no-repeat;
  			background-position: right 16px center;
			}
		}
	}
	&-radio-group
	{
		padding-top:16px;
		padding-bottom:16px;
		&.\--inline
		{
			@include snap-up-from('sm')
			{
				display: flex;
				flex-wrap:wrap;
				align-items:flex-end;
				p
				{
					flex-basis:100%
				}
				.rwx-form-item
				{
					&:not(:nth-of-type(3n))
					{
						padding-right:24px;
					}
					flex-basis:33%;
					label
					{
						flex-basis:auto;
					}
				}
			}
		}
		&.required
		{
			p{@include required;}
		}
	}
}