@import '../core/rwx-variables';
$rwxsx-distance: 33%;
.rwxsx
{
	&-end:not(\.--elastic)
	{
		transform: translate(0, 0) !important;
		opacity: 1 !important;
		transition: all 0.63s $transition-slow-in;
		@each $sel, $v in $transitions
		{
			&.\--#{$sel}
			{
				transition: all 0.63s $v;
			}
		}
	}
	&-start
	{
		$direction: (	'left': -#{$rwxsx-distance}#{','} 0, 
									'right':  #{$rwxsx-distance}#{','} 0,
									'top': 0#{','} -#{$rwxsx-distance},
									'bottom': 0#{','} #{$rwxsx-distance},
									'top-left': -#{$rwxsx-distance}#{','} -#{$rwxsx-distance},
									'top-right': #{$rwxsx-distance}#{','} -#{$rwxsx-distance},
									'bottom-left': -#{$rwxsx-distance}#{','} #{$rwxsx-distance},
									'bottom-right': #{$rwxsx-distance}#{','} #{$rwxsx-distance},
									'initial': 0#{','} 0);
		@each $selector, $transform in $direction
		{
			&-#{$selector}
			{
				opacity:0;
				transform: translate($transform);
			}
		}
	}
}