$section-seperators: false;
$grid-item-gutter: 24px;

@import "~roseworx/scss/roseworx";
@import "~roseworx/scss/components/rwxTimelines";
@import "./project";
@import "./intro";

body {
	overflow:hidden;
}

#init-loading
{
	width:300px;
	height:300px;
	border-radius:150px;
	background:$grey;
	opacity:0.5;
}

.scroll-icon
{
 	position: absolute;
  bottom: 20px;
  left: 20px;
  animation: 3s scroll ease-in-out infinite;
}

@keyframes scroll {
	0% {
		bottom: 10px;
	}
	30% {
		bottom: 30px;
	}
	60% {
		bottom: 10px;
	}
	100% {
		bottom: 10px;
	}
}

.scroll-container, .intro-background, .black-hole
{
	height:100vh;
	width:100%;
}

#black-hole-container
{
	position:relative;
}

.contact-statement
{
	position:absolute;
	width:100%;
	bottom:0px;
	left:0px;
	background: rgba($black,0.5);
	z-index:6;
}

.scroll-container
{
	z-index:1;
	position: fixed;
	left:0px;
	top:0px;
	#system
	{
		z-index:-1;
	}
}

main.main-content
{
	position: relative;
	z-index:4;
	margin-top:100vh;
	padding-bottom:100vh;
}

section
{
	perspective: 800px;
	position: relative;
	&:not(:last-of-type)
	{
		padding-bottom:50vh;
	}
	&.rwxsx-end
	{
		.section-content
		{
	    transform: translateZ(0px) rotateY(0deg) scale(1);
	    opacity:1;
		}
	}
	.section-content
	{
	  backface-visibility: hidden;
	  transform-style: preserve-3d;
	  transition: all 1.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transform: translateZ(-800px) rotateY(-180deg) scale(0.1);
    opacity:0;
    @include snap-down-from('md')
    {
    	padding:0px;
    }
    > div
    {
    	box-shadow: 0px -3px 22px 6px $dark-grey;
    	@include snap-up-from('md')
    	{
    		margin-top:5vh;
    	}
    	@include snap-down-from('md')
    	{
    		height:92vh;
    		max-height: 92vh;
    	}
			height:90vh;
			max-height:90vh;
			overflow:auto;
    }
	}
}