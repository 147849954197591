$rwx-loading-background-color: $grey !default;
$rwx-loading-color: $white !default;

[rwx-loading], .rwx-loading
{
	overflow:hidden;
	position:relative;
	&:before
	{
		content:"";
		overflow:hidden;
		z-index:9998;
		@include full-size;
		background: rgba($rwx-loading-background-color, 0.5);
	}
	&:after
	{
		z-index: 9999;
		animation: loading 2s $transition-slow-in-slow-out infinite;
		content: "";
		position:absolute;
		height:200%;
		width:200%;
		background:linear-gradient(to bottom, rgba($rwx-loading-background-color,0), rgba($rwx-loading-color,0) 40%, rgba($rwx-loading-color,1) 50%, rgba($rwx-loading-color,0) 60%, rgba($rwx-loading-background-color,0));
		transform:rotate(-45deg);
	}
}

@keyframes loading {
	0% {
		left:-150%;
		top: -150%;
	}
	100% {
		left: 150%;
		top: 100%;
	}
}