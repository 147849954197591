.rwxu-hide
{
	display:none !important;
	&-rsp
	{
		@include snap-down-from('md')
		{
			display: none !important;
		}
	}
}

.rwxu-show
{
	display:initial !important;
	display:revert !important;
	&-rsp
	{
		display:none !important;
		@include snap-down-from('md')
		{
			display: initial !important;
			display:revert !important;
		}
	}	
}

.rwxu-flex-center
{
	@include flex-center;
}

.rwxu-absolute-center
{
	@include absolute-center;
}

.rwxu-full-size
{
	@include full-size;
}

.rwxu-pointer
{
	cursor:pointer;
}

