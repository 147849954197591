.intro-background
{
  background:linear-gradient(to right, #0f0c29, #302b63, #24243e);
  .intro
  {
    width:100%;
    z-index:2;
    .persp
    {
      perspective:1000px;
      h1
      {
        display:inline-block;
        transform-style: preserve-3d;
        font-size:6rem;
        line-height:6rem;
        padding: 32px 16px;
        opacity:0;
        @include snap-down-from('md')
        {
          font-size: 4rem;
          line-height:4rem;
          padding: 24px 8px;
        }
      }
    }
  }
}