$iconsizes: ('xs': 1rem, 'sm': 2rem, 'lg': 4rem, 'xl': 5rem);

@if $allow-icons
{
	@each $iconname, $stuff in $icons
	{
		$icon:null;
		$fill: false;
		$sw: false;
		@if map-has-key($icons, $iconname) {
			$icon: map-get($icons, $iconname);
			@if map-get($icon, 'fill')
			{
				$fill: true;
			}
			@if map-get($icon, 'strokewidth')
			{
				$sw: map-get($icon, 'strokewidth');
			}
			@else
			{
				$sw: $icon-line-width;
			}
		}

		.rwxi-#{$iconname}
		{
			background: icon($iconname, $icon-color-base, $sw, $fill) no-repeat;
			width:3rem;
			height:3rem;
			display:inline-block;
			@if $allow-sized-icons
			{
				@each $size, $measurement in $iconsizes
				{
					&.\--#{$size}
					{
						width: $measurement;
						height: $measurement;
					}
				}
			}
			@if $allow-colored-icons
			{
				@each $modifier, $color in $colors
				{
					&.\--#{$modifier}
					{
						background: icon($iconname, $color, $sw, $fill) no-repeat;
					}
				}
			}
		}
	}
	.rwxi
	{
		&-uchev, &-dchev, &-rchev, &-lchev
		{
			@extend .rwxi-chev;
		}
		&-uchev
		{
			transform: rotate(270deg);
		}
		&-dchev
		{
			transform: rotate(90deg);
		}
		&-lchev
		{
			transform: rotate(180deg);
		}
	}
}