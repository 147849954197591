.rwx-slider
{
	position:relative;
	display:flex;
	overflow:hidden;
	& > &-slide
	{
		width:100%;
		flex-shrink:0;
		transition: transform 0.7s $transition-base;
	}

	&[data-rwx-slider-vertical="true"]
	{
		flex-direction:column;
	}

	&[data-rwx-slider-vertical="true"] &-counters
	{
		left:5px;
		flex-direction:column;
		top:50%;
		transform: translateY(-50%);
		&-counter
		{
			&:not(:last-child)
			{
				margin-right:0px;
				margin-bottom:20px;
			}
		}
	}

	&[data-rwx-slider-counters]
	{
		&:not([data-rwx-slider-vertical="true"])
		{
			padding-bottom:$slider-counter-size+30;
			@include snap-down-from('md')
			{
				padding-bottom:$slider-counter-size+40;
			}
		}
		&[data-rwx-slider-vertical="true"]
		{
			padding-left:$slider-counter-size+30;
			@include snap-down-from('md')
			{
				padding-left:$slider-counter-size+40;
			}
		}
	}

	&:not([data-rwx-slider-vertical="true"]) &-counters
	{
		bottom:5px;
		width:100%;
	}

	&.\--counter-circle &-counters
	{
		&-counter
		{
			border-radius: 100%;
		}
	}

	&-counters
	{
		position:absolute;
		justify-content:center;
		display:flex;
		&-counter, &-counter.no-decoration
		{
			flex-shrink:0;
			cursor:pointer;
			margin-bottom:0;
			display:block;
			width:$slider-counter-size;
			height:$slider-counter-size;
			background: $slider-counter-inactive-color;
			transition: background 0.7s $transition-base;
			&:focus
			{
				@include focus-ring;
			}
			&:not(:last-child)
			{
				margin-right:20px;
			}
			&.active
			{
				background: $slider-counter-active-color;
				transition: background 0.7s $transition-base;
			}
			@include snap-down-from('md')
			{
				width: $slider-counter-size + 10;
				height: $slider-counter-size + 10;
			}
		}
	}
}